import React, { useState, useEffect } from 'react';
import LoadingOverlay from '../LoadingOverlay';
import "./Loading.css?__remix_sideEffect__";

const LOADING_DELAY = 200;

const Loading = () => {
  const [pastDelay, setPastDelay] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPastDelay(true);
    }, LOADING_DELAY);
    return () => clearTimeout(timer);
  }, []);

  if (pastDelay) {
    return (
      <div className="loadable-loading">
        <LoadingOverlay inlineDisplay={false} />
      </div>);

  }

  return null;
};

export default Loading;