import React, { Suspense, lazy, useState, useEffect } from 'react';
import Loading from './Loading';

/**
 * Loads a component asynchronously and allows CRA to codesplit the component
 * and its children into a separate file chunk.
 */
const Loadable = ({ loader }) => props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [Loader, setLoader] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const result = lazy(loader);
    setLoader(result);
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      {Loader ? <Loader {...props} /> : null}
    </Suspense>
  );
};

export default Loadable;
